import React from 'react';
import { Link } from 'react-router-dom';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

import { withConfig } from 'config/';
import { ProfileItemProps } from '../model';
// import { clearSearchQuery } from 'store/actions';

const styles = createStyles({
  contentItem: {
    padding: '10px 0',
  },
  contentTitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  contentSubtitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: '.5px',
    marginRight: 5,
    whiteSpace: 'pre-wrap',
  },
});

interface Props extends WithStyles<typeof styles>, ProfileItemProps {}

const ProfileItem: React.FC<Props> = ({
  classes,
  theme,
  title,
  value,
  navigationMetadata,
  prefix,
  prefixNavigationMetadata,
}) => {
  const optionalSource =
    (navigationMetadata &&
      navigationMetadata.optionalSource &&
      `&optionalSource=${navigationMetadata.optionalSource}`) ||
    '';
  const prefixOptionalSource =
    (prefixNavigationMetadata &&
      prefixNavigationMetadata.optionalSource &&
      `&optionalSource=${prefixNavigationMetadata.optionalSource}`) ||
    '';

  const ValueField = () =>
    !navigationMetadata ? (
      <p className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
        {Array.isArray(value) ? value.join(', ') : String(value)}
      </p>
    ) : (
      <Box flexDirection={'row'} display="flex">
        {!!prefix?.length && (
          <Link to={prefixNavigationMetadata.type + `?searchKey=${prefix}${prefixOptionalSource}`}>
            <span className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
              {prefix}
            </span>
          </Link>
        )}
        <Link to={navigationMetadata.type + `?searchKey=${value}${optionalSource}`}>
          <span className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
            {value}
          </span>
        </Link>
      </Box>
    );

  return (
    <div className={classes.contentItem}>
      <span className={classes.contentTitle} style={{ color: theme.TEXT_SECONDARY }}>
        {title}:{' '}
      </span>
      <ValueField />
    </div>
  );
};

export default withStyles(styles)(withConfig(ProfileItem));
