import { Config, Theme } from '../../config/';

interface Corps {
  _id: string;
  charge: string[] | string;
  mentee: string;
  mentor: string;
  corpsname: string;
  rezeptionsdatum: string;
  status: string;
  verified: number;
  biername: string;
}

interface User {
  [key: string]: any;
  _id: string;
  name: string;
  lastname: string;
  photo: string;
  email: string;
  corp: Array<Corps>;
}

export interface ProfileDetailsProps {
  theme: Theme;
  config: Config;
  user: User;
  currentUser: User;
  userIsLoading: boolean;
  getUserProfileRequest: (id: string) => void;
}

export interface ProfileDetailsState {
  openLightbox: boolean;
}

export interface ProfileItemProps {
  theme: Theme;
  title: string;
  value: string;
  navigationMetadata: {
    type: Partial<ProfileItemRedirectTypes>;
    entity: string;
    optionalSource?: string;
  };
  prefix?: string;
  prefixNavigationMetadata?: {
    type: Partial<ProfileItemRedirectTypes>;
    entity: string;
    optionalSource?: string;
  };
  clearSearchQuery: () => void;
}

export enum ProfileItemRedirectTypes {
  Name = '/suche/name',
  Clubs = '/suche/corpsname/users',
  Stadt = '/suche/stadt/users',
  PostCode = '/suche/postcode',
  Country = '/suche/land/users',
  Branche = '/suche/industry/users',
  Firma = '/suche/company/users',
  Beruf = '/suche/beruf/users',
  Interessen = '/suche/interessen/users',
  Hochschule = '/suche/hochschule/users',
  Studiengang = '/suche/studiengang/users',
  Function = '/suche/funktion/users',
  Activity = '/suche/activity/users',
}

export interface ProfileAvatarProps {
  theme: Theme;
  image: string;
  onClick: () => void;
}

export interface ProfileLinkProps {
  theme: Theme;
  title: string;
  value: string;
  link: string;
  navigationMetadata: {
    type: Partial<ProfileItemRedirectTypes>;
    entity: string;
  };
  clearSearchQuery: () => void;
}
interface Interessen {
  _id: string;
  titel: string | undefined;
  title: string | undefined;
}
export interface ProfileInteressenProps {
  theme: Theme;
  interessenList: Array<Interessen>;
  navigationMetadata: {
    type: Partial<ProfileItemRedirectTypes>;
    entity: string;
  };
  clearSearchQuery: () => void;
}

export interface ProfileInfoProps {
  theme: Theme;
  name: string;
  status: string;
  corps: Array<Corps>;
  branch: string;
  contacts: string;
  biername: string;
}

export interface ProfileCorpsProps {
  theme: Theme;
  corpsList: Array<Corps>;
  navigationMetadata: {
    type: Partial<ProfileItemRedirectTypes>;
    entity: string;
  };
  clearSearchQuery: () => void;
}
