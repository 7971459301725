import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, TextareaAutosize } from '@material-ui/core';
import { Refresh, Stop } from '@material-ui/icons';
import TagFaces from '@material-ui/icons/TagFaces';
import { useFormik } from 'formik';

import { withConfig } from '../../config';
import Button from '../UI/Button';
import { useLanguage } from 'languages/languageContext';
import { RootState, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import {
  addNews,
  cancelEditing,
  createSocialMediaPost,
  selectNews,
  setSocialMediaPost,
  setSocialMediaPostInitialState,
  submitEditing,
} from 'store/reducers/news';
import { UploadMedia } from './UploadMedia';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import { mediaActions, selectMediaState, trimMedia } from 'store/reducers/mediaSlice';
import { ReactComponent as DeleteIcon } from '../../assets/delete_button_blue.svg';
import { generateSocialText, generateSubject } from 'api/news';
import { TextField } from 'scenes/Mentoring/components/TextField';
import DefaultModal from './DefaultModal';
import Loader from 'components/UI/Loader';
import { privacyValueTypes } from 'scenes/ProfilePrivacy/ProfilePrivacy';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import { SubjectModal } from './SubjectModal';
import { IonIcon } from 'components/UI/IonIcon';
import { addComment } from 'store/reducers/commentsSlice';
import { showResponse } from 'store/actions/response';
import { updateUserRequest } from 'store/actions/users';
import { SearchModal } from './SearchModal/SearchModal';
import { Modal } from './Modal/Modal';
import Survey, { TEventSurvey } from './Survey/Survey';
import { Button as NewButton } from '../shadcn/button';
import { surveyValidation } from 'utilities/schema';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import { EmojiPicker } from 'components/UI/EmojiPicker';
import { listenForOutsideClicks } from 'utilities/listenForOutsideClicks';
import { extractUrls } from 'utilities/validation';

// import { SurveyModal, TSurveyModal } from './SurveyModal/SurveyModal';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    position: 'fixed',
    bottom: 0,
    width: '600px',
    padding: '10px',
    zIndex: 10,
    boxSizing: 'border-box',
    boxShadow: '1px 0 5px 0 #ccc',
    background: theme.BACKGROUND_PRIMARY,
  },
  inputWrapper: {
    position: 'relative',
    flexGrow: 1,
    marginRight: 10,
    boxSizing: 'border-box',
    paddingLeft: 10,
  },
  input: {
    fontFamily: 'Poppins',
    padding: '5px 0',
    border: 'none',
    outline: 'none',
    fontSize: 15,
    resize: 'none',
    width: '100%',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      paddingTop: 2,
    },
    background: theme.BACKGROUND_PRIMARY,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    textAlign: 'center',
    borderRadius: 50,
    color: '#fff',
  },
  attachmentsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px 0 0 0',
  },
  image: {
    position: 'relative',
    maxWidth: 120,
    maxHeight: 80,
    backgroundSize: 'contain',
  },
  pdfImage: {
    position: 'relative',
    maxWidth: 60,
    maxHeight: 40,
    backgroundSize: 'contain',
  },
  pdfPreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  deleteButton: {
    position: 'absolute',
    top: -10,
    right: -15,
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.BACKGROUND_SECONDARY,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  '@media(max-width: 1024px)': {
    container: {
      padding: '10px',
      width: '460px',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
    inputWrapper: {
      paddingLeft: 10,
    },
  },
  editing: {
    minHeight: 20,
    backgroundColor: '#ccc',
    borderRadius: 10,
    width: 170,
    alignItems: 'center',
    padding: 5,
    justifyContent: 'space-between',
    display: 'flex',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  socialDescription: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: theme.ICON_BORDER,
    margin: '10px 0px',
    textAlign: 'center',
    lineHeight: 1.6,
    display: 'inline-block',
  },
  emojiButton: {
    background: theme.BACKGROUND_SECONDARY,
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
});

interface Props extends WithStyles<typeof styles> {
  postId: string;
  kreiseId?: string;
  toolbarType: string;
  kreiseName?: string;
}

export type subjectModalType = {
  show: boolean;
  value: string;
  subjectId: string;
  loading: boolean;
  editable: boolean;
  shouldBeShown: boolean;
  firstStep: boolean;
  withoutGeneration: boolean;
};

const CommentToolbar: React.FC<Props> = ({ classes, postId, kreiseId, toolbarType, kreiseName = '' }) => {
  const {
    post,
    btn,
    privacy: { guestModal },
    invalidInputs,
    ...language
  } = useLanguage();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users.user);
  const { disable_guest_function, guest_naming_plural, newsfeed_popup, support_email } = useAppSelector(
    (state) => state.common,
  );
  const { editingPost, socialMediaPost } = useAppSelector(selectNews);
  const isEditing = !!editingPost?._id?.length;
  const [content, setContent] = useState<string>('');

  const [surveyModal, setSurveyModal] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, ...formik } = useFormik({
    initialValues: {
      questions: [] as TEventSurvey[],
    },
    validationSchema: surveyValidation({ invalidInputs }),
    onSubmit: () => {
      setSurveyModal(false);
    },
  });

  const subjectModalInitialState = {
    show: false,
    value: '',
    subjectId: null,
    loading: false,
    editable: false,
    shouldBeShown: true,
    // used to change button name for subjectSuggestion = optional
    firstStep: true,
    withoutGeneration: false,
  };

  const [subjectModal, setSubjectModal] = useState(subjectModalInitialState);
  const showToGuestInitialState: { show: boolean; remember: boolean; value: privacyValueTypes } = {
    show: false,
    remember: false,
    value: 'ask',
  };
  const [showToGuestsModal, setShowToGuestsModal] = useState<{
    show: boolean;
    remember: boolean;
    value: privacyValueTypes;
  }>(showToGuestInitialState);

  const subjectController = useRef(new AbortController());
  const socialController = useRef(new AbortController());

  const { documents, videos, images, loading, isLoadingAllAttachments } = useAppSelector(selectMediaState);
  const attachments = { documents, videos, images };

  const { kreiseForNews } = useAppSelector((state) => state.news);

  const defaultKreiseToPost =
    kreiseForNews?.filter((kreise) => kreise.isTicked) || ([] as { _id: string; name: string }[]);
  const [kreiseModal, setKreiseModal] = useState({
    show: false,
    kreiseToPost: defaultKreiseToPost,
    hasToBeShown: true,
  });

  const sendBtnDisabled =
    user.isGuest ||
    (!!(
      content.length ||
      attachments.documents?.length ||
      attachments.videos?.length ||
      attachments.images?.length ||
      values.questions?.[0]?.text
    ) &&
      isLoadingAllAttachments);

  useEffect(() => {
    setKreiseModal((c) => ({ ...c, kreiseToPost: defaultKreiseToPost }));
  }, [kreiseForNews]);

  useEffect(() => {
    if (!isEditing) return;
    setContent(editingPost.content);
    return () => {
      dispatch(cancelEditing());
      dispatch(mediaActions.deleteAllAttachments());
    };
  }, [isEditing]);

  useEffect(() => {
    if (user?.privacy?.showPostsToGuests)
      setShowToGuestsModal((c) => ({ ...c, value: user?.privacy?.showPostsToGuests }));
  }, [user?.privacy?.showPostsToGuests]);

  const onAddComment = async ({
    askForGuests,
    newsfeedOptions,
  }: {
    askForGuests?: privacyValueTypes;
    newsfeedOptions?: { _id: string; name: string; isTicked: boolean }[];
  } = {}) => {
    if (user.isGuest) {
      dispatch(showResponse({ message: 'Nur Mitglieder können Beiträge verfassen.' }));
      return;
    }

    const userId = localStorage.getItem('userId');
    let postEntity;

    const media = trimMedia(attachments);

    if (toolbarType === 'post' && isEditing) {
      postEntity = {
        ...editingPost,
        content,
        ...media,
      };
      dispatch(submitEditing({ postEntity, type: kreiseId?.length ? 'kreise' : 'feed' }));
    }

    if (toolbarType === 'post' && !isEditing) {
      if (!kreiseId && newsfeed_popup && kreiseForNews.length) {
        if (kreiseModal.hasToBeShown) {
          setKreiseModal((c) => ({ ...c, show: true, hasToBeShown: false }));
          return;
        }
        if (newsfeedOptions?.length) {
          setKreiseModal((c) => ({ ...c, kreiseToPost: newsfeedOptions, show: false }));
        }
      }

      if (subjectModal.shouldBeShown && !content.length) {
        setSubjectModal((c) => ({
          ...c,
          show: true,
          editable: true,
          shouldBeShown: false,
          withoutGeneration: true,
        }));
        return;
      }

      if (subjectModal.shouldBeShown && content.length && user.subjectSuggestion === 'optional') {
        setSubjectModal((c) => ({ ...c, show: true, editable: true, shouldBeShown: false }));
        return;
      }

      if (subjectModal.shouldBeShown && content.length && user.subjectSuggestion === 'standard') {
        generateSubjectRequest();
        return;
      }

      if (showToGuestsModal.value === 'ask' && !disable_guest_function) {
        if (!askForGuests?.length) {
          setSubjectModal((c) => ({ ...c, show: false }));
          setShowToGuestsModal((c) => ({ ...c, show: true }));
          return;
        }
      }

      const { value, subjectId } = subjectModal;
      const subject = !value.length ? {} : subjectId?.length ? { subjectId } : { subject: value.trim() };

      const kreiseToPost = kreiseModal.kreiseToPost?.length ? kreiseModal.kreiseToPost : newsfeedOptions;
      const post_type =
        !kreiseId && newsfeed_popup && kreiseForNews?.length
          ? kreiseToPost?.map((kreise) => kreise._id)
          : kreiseId?.length
          ? [kreiseId]
          : 'feed';

      postEntity = {
        content,
        poster: user._id,
        post_type,
        showToGuests: askForGuests || user.privacy.showPostsToGuests,
        ...values,
        ...subject,
        ...media,
      };
      dispatch(addNews({ postEntity }));
    }
    if (toolbarType === 'post-comment') {
      postEntity = {
        post_id: postId,
        commenter: userId,
        content,
        ...values,
        // ...survey,
        ...media,
      };

      dispatch(addComment({ comment: postEntity }));
    }

    setSubjectModal(subjectModalInitialState);
    setShowToGuestsModal(showToGuestInitialState);
    setInitialState();
    formik.resetForm();
  };

  const setInitialState = () => {
    // dispatch(setSocialMediaPostInitialState())
    setSubjectModal(subjectModalInitialState);
    setContent('');
    setKreiseModal({
      show: false,
      kreiseToPost: defaultKreiseToPost,
      hasToBeShown: true,
    });
    dispatch(mediaActions.deleteAllAttachments());
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value.slice(0, 20000));
  };

  const onBlurHandler = (e) => {
    setContent(e.target.value.trim());
  };

  const postPlaceholder = kreiseId ? post.sendToCircle : newsfeed_popup ? post.enterText : post.sendToContacts;
  const commentPlaceholder = post.sendComment;

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const generateSubjectRequest = async (force = false) => {
    try {
      if (!force && (subjectModal.subjectId || subjectModal.value)) {
        setSubjectModal((c) => ({ ...c, show: true, shouldBeShown: false, firstStep: false }));
        return;
      }
      setSubjectModal((c) => ({ ...c, show: true, loading: true, shouldBeShown: false, firstStep: false }));
      const res: any = await generateSubject(content, subjectController.current);
      setSubjectModal((c) => ({ ...c, show: true, loading: false, value: res.subject, subjectId: res._id }));
    } catch (e) {
      setSubjectModal((c) => ({
        ...c,
        show: true,
        loading: false,
        value: `Beitrag an “${kreiseName || 'Alle'}”`,
        subjectId: '',
      }));
    }
  };

  const generateSocialRequest = async () => {
    try {
      dispatch(setSocialMediaPost({ show: true, loading: true }));
      const res: any = await generateSocialText(socialMediaPost.originalText, subjectController.current);
      dispatch(setSocialMediaPost({ show: true, loading: false, value: res.text }));
    } catch (e) {
      dispatch(setSocialMediaPost({ show: true, loading: false, value: socialMediaPost.originalText }));
    }
  };

  const DeleteButton = () =>
    isEditing && (
      <div className={classes.editing}>
        <span>{post.editingPostLabel}</span>
        <ButtonBase
          style={{ background: theme.BACKGROUND_SECONDARY, borderRadius: 100 }}
          onClick={() => {
            dispatch(cancelEditing());
            dispatch(mediaActions.deleteAllAttachments());
            setContent('');
          }}
        >
          <DeleteIcon />
        </ButtonBase>
      </div>
    );

  const abortSubjectSignal = () => {
    if (!subjectController.current) return;
    subjectController.current.abort();
    subjectController.current = new AbortController();
  };

  const abortSocialSignal = () => {
    if (!socialController.current) return;
    socialController.current.abort();
    socialController.current = new AbortController();
  };

  const handleSocialMediaPost = () => {
    if (socialMediaPost.step === 1) {
      dispatch(setSocialMediaPost({ step: 2 }));
      if (!!socialMediaPost.originalText.length) generateSocialRequest();
    }
    if (socialMediaPost.step === 2) {
      dispatch(setSocialMediaPost({ step: 3 }));
      dispatch(
        createSocialMediaPost({
          kreiseId: socialMediaPost.kreiseId,
          value: socialMediaPost.value,
          media: socialMediaPost.media,
        }),
      );
    }
  };

  const handleRejectSocialMedia = () => {
    if (socialMediaPost.step !== 2) {
      dispatch(setSocialMediaPostInitialState());
    }
    dispatch(
      setSocialMediaPost({
        editable: true,
        value: !socialMediaPost.originalText.length
          ? 'Ein:e Nutzer:in unser App hat ein neues Bild geteilt!'
          : socialMediaPost.value,
      }),
    );
  };

  const handleShowToGuests = (value: 'yes' | 'no') => {
    setShowToGuestsModal((c) => ({ ...c, value, show: false }));
    if (showToGuestsModal.remember) {
      // logic to save settings in profile if remember is checked
      const updatedUser = {
        ...user,
        privacy: {
          showPostsToGuests: value,
        },
      };
      dispatch(updateUserRequest(updatedUser, true));
    }
    onAddComment({ askForGuests: value });
  };

  const [url, setUrl] = useState<string | null>(null);
  const [showLinkPreview, setShowLinkPreview] = useState(false);
  const [isDisabledLinkPreview, setIsDisabledLinkPreview] = useState(false);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (toolbarType !== 'post') return;

      if (content === '') {
        setIsDisabledLinkPreview(false);
      }

      if (isDisabledLinkPreview) return;

      try {
        const urls = await extractUrls(content?.trim());
        if (urls && urls.length > 0) {
          setUrl(urls[0]);
          setShowLinkPreview(true);
        } else {
          setUrl(null);
          setShowLinkPreview(false);
        }
      } catch (error) {
        console.error('Error extracting URLs:', error);
        setUrl(null);
        setShowLinkPreview(false);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [content, toolbarType, isDisabledLinkPreview]);

  // Emoji picker handling
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [listening, setListening] = useState(false);

  const cursorPositionRef = useRef(0);
  const toolbarRef = useRef(null);
  const valueRef = useRef(content);

  const handleEmojiClick = useCallback(
    (unicode: string) => {
      const currentValue = valueRef.current;
      const currentCursorPosition = cursorPositionRef.current;

      let newValue = `${currentValue.slice(0, currentCursorPosition)}${unicode}${currentValue.slice(
        currentCursorPosition,
      )}`;
      newValue = limitValueLenght(newValue);
      onChangeHandler({ target: { value: newValue } } as React.ChangeEvent<HTMLTextAreaElement>);
      valueRef.current = newValue;
      cursorPositionRef.current += unicode.length;
    },
    [onChangeHandler],
  );

  const updateCursorPosition = useCallback((e) => {
    cursorPositionRef.current = e.currentTarget.selectionStart;
  }, []);

  const handleTextareaChange = useCallback(
    (e) => {
      e.target.value = limitValueLenght(e.target.value);
      onChangeHandler(e);
      valueRef.current = e.target.value;
      updateCursorPosition(e);
    },
    [onChangeHandler, updateCursorPosition],
  );

  const limitValueLenght = (value?: string) => value?.slice(0, 20000) || '';

  useEffect(listenForOutsideClicks(listening, setListening, toolbarRef, setPickerVisible));

  return (
    <>
      <div ref={toolbarRef} className={classes.container}>
        <DeleteButton />
        <RenderStateAttachments
          currentAttachments={attachments}
          deleteAttachment={deleteAttachment}
          survey={{
            surveyAdded: !!values.questions.length,
            showModal: () => setSurveyModal(true),
            deleteSurvey: () => {
              formik.resetForm();
            },
          }}
          viewType="column"
        />
        <EmojiPicker isVisible={isPickerVisible} onEmojiClick={handleEmojiClick} />
        <div className={classes.wrapper}>
          <button className={classes.emojiButton} onClick={() => setPickerVisible(!isPickerVisible)}>
            <TagFaces style={{ color: theme.BUTTON_PRIMARY, fontSize: 28 }} />
          </button>
          <UploadMedia
            additionalBtn={[
              <ButtonBase
                onClick={() => {
                  setSurveyModal(true);
                  formik.setValues({
                    questions: [
                      {
                        type: 'single',
                        text: '',
                        isAnonym: false,
                        options: [{ title: '' }, { title: '' }],
                      },
                    ],
                  });
                }}
                style={{ width: 24, height: 24, marginBottom: 15, marginTop: 5 }}
              >
                <IonIcon name="podium-outline" size={20} color={'#2671D1'} style={{ rotate: '90deg' }} />
              </ButtonBase>,
            ]}
          />
          <div className={classes.inputWrapper}>
            <TextareaAutosize
              rowsMax={6}
              style={{ background: theme.BACKGROUND_PRIMARY }}
              className={classes.input}
              placeholder={toolbarType === 'post' ? postPlaceholder : commentPlaceholder}
              value={content}
              onChange={handleTextareaChange}
              onClick={updateCursorPosition}
              onFocus={updateCursorPosition}
              onKeyDown={updateCursorPosition}
              onBlur={onBlurHandler}
            />
            {content.length < 19999 || (
              <span style={{ color: theme.ERROR_PRIMARY, marginBottom: 10 }}>Maximal 20.000 Zeichen.</span>
            )}
          </div>
          <div className={classes.buttonsWrapper}>
            <ButtonBase
              onClick={() => {
                onAddComment({});
                valueRef.current = '';
                setPickerVisible(false);
                setListening(false);
              }}
              disabled={!sendBtnDisabled}
              className={classes.button}
              style={{ background: !sendBtnDisabled ? '#ccc' : theme.BUTTON_PRIMARY }}
            >
              <IonIcon name="paper-plane-outline" color="#FFF" size={28} style={{ height: 29, width: 29 }} />
            </ButtonBase>
          </div>
        </div>
      </div>

      {/* set email subject (gpt) */}
      <SubjectModal
        setSubjectModal={setSubjectModal}
        subjectModal={subjectModal}
        user={user}
        onAddComment={onAddComment}
        generateSubjectRequest={generateSubjectRequest}
        abortSubjectSignal={abortSubjectSignal}
        kreiseName={kreiseName}
      />

      {/* show to guests modal */}
      <DefaultModal
        showModal={showToGuestsModal.show}
        modalTitle={guest_naming_plural}
        onClose={() => {
          setShowToGuestsModal({ ...showToGuestsModal, show: false });
          setSubjectModal((c) => ({ ...c, shouldBeShown: true }));
        }}
        message={guestModal.title}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CheckboxUI
            label={guestModal.dontAskAgain}
            isChecked={showToGuestsModal.remember}
            setIsChecked={() => setShowToGuestsModal((c) => ({ ...c, remember: !c.remember }))}
            wrapperStyle={{ alignSelf: 'center', width: 460 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignSelf: 'center',
              marginTop: 20,
              width: '100%',
            }}
          >
            <Button bottomMargin={3} width={150} label={'Ja'} onClick={() => handleShowToGuests('yes')} />
            <Button bottomMargin={3} width={150} label={'Nein'} onClick={() => handleShowToGuests('no')} />
          </div>
        </div>
        {/* <Loader showLoader={subjectModal.loading} /> */}
      </DefaultModal>
      {/* show to guests modal */}

      {/* share on social media */}
      <DefaultModal
        showModal={socialMediaPost.show}
        modalTitle={'Social Media'}
        onClose={() => dispatch(setSocialMediaPost({ show: false }))}
        message={
          socialMediaPost.step === 1
            ? 'Danke für deinen Beitrag! Dürfen wir dein Foto auch für die Social Media Kanäle verwenden?'
            : 'Wir generieren einen Text für Social Media. Passt dieser Text?'
        }
      >
        <div style={{ padding: '0 6% 10px 9%' }}>
          {socialMediaPost.step !== 1 && (
            <div style={{ padding: '0 5% 0 5%' }}>
              <TextField
                disabled={!socialMediaPost.editable}
                withMedia={false}
                value={socialMediaPost.value}
                onChangeHandler={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  dispatch(setSocialMediaPost({ ...socialMediaPost, value: e.target.value }));
                }}
              />
            </div>
          )}
          {socialMediaPost.step === 1 ? (
            <span className={classes.socialDescription}>
              Nur Mut! Du hast im nächsten Schritt noch die Möglichkeit, einen eigenen Text hinzuzufügen. Zudem wird
              dein Beitrag noch einmal geprüft.
            </span>
          ) : (
            <span className={classes.socialDescription}>
              Nach dem Absenden wird dein Beitrag noch von einem Administrator überprüft. Bei Fragen wende dich gern an{' '}
              <a href={`mailto:${support_email}`} style={{ textDecoration: 'none' }}>
                {support_email}
              </a>
              {' oder '}
              <a href={'tel:+491704980594'} style={{ textDecoration: 'none' }}>
                +49 170 49 805 94
              </a>
              .
            </span>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignSelf: 'center',
              marginTop: 20,
            }}
          >
            <Button
              bottomMargin={3}
              width={150}
              label={socialMediaPost.step === 1 ? 'Ja' : 'Passt'}
              onClick={handleSocialMediaPost}
            />
            {socialMediaPost.step === 2 && !!socialMediaPost.originalText?.length && (
              <ButtonBase
                style={{
                  backgroundColor: theme.ACTIVE_INPUT,
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  zIndex: 99,
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
                onClick={() => (socialMediaPost.loading ? abortSocialSignal : generateSocialRequest)}
              >
                {socialMediaPost.loading ? (
                  <Stop onClick={abortSocialSignal} style={{ color: theme.COLOR_PRIMARY, width: 20 }} />
                ) : (
                  <Refresh onClick={generateSocialRequest} style={{ color: theme.COLOR_PRIMARY, width: 20 }} />
                )}
              </ButtonBase>
            )}
            <Button
              bottomMargin={3}
              width={150}
              label={socialMediaPost.step === 1 ? 'Nein' : socialMediaPost.step === 2 ? 'Ändern' : 'Abbrechen'}
              onClick={handleRejectSocialMedia}
            />
          </div>
        </div>
        <Loader showLoader={subjectModal.loading || socialMediaPost.loading} />
      </DefaultModal>
      {/* share on social media */}

      <SearchModal
        data={kreiseForNews}
        isOpen={kreiseModal.show}
        handleClose={() => setKreiseModal({ kreiseToPost: defaultKreiseToPost, show: false, hasToBeShown: true })}
        onConfirm={(selectedValues: typeof kreiseForNews) => {
          onAddComment({ newsfeedOptions: selectedValues });
        }}
        preselectedValues={kreiseModal.kreiseToPost}
        maxSelectedCount={9999}
        modalTitle="Gruppenchat auswählen"
        message="Wähle mindestens 1 Chat aus den Favoriten aus, an den dein Beitrag geschickt werden soll. Weitere findest du im Hauptmenü unter “Gruppenchats”."
      />

      <Modal
        modalTitle={
          values.questions?.[0]?.type === 'quiz' ? language.eventLabels.parts.quiz : language.surveys.createModalTitle
        }
        isOpen={surveyModal}
        handleClose={() => {
          setSurveyModal(false);
          formik.resetForm();
        }}
      >
        <div className="max-h-[80vh] overflow-y-auto">
          <div className="m-6 w-72 lg:w-96">
            <Survey
              parentComponent="post"
              values={values?.questions?.[0]}
              // @ts-ignore
              errors={errors?.questions?.[0]}
              touched={touched?.questions?.[0]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              idx={0}
              setValues={(qValues) => {
                const upd = values.questions;
                upd[0] = qValues;
                formik.setValues({ ...values, questions: upd });
              }}
              surveyIdx={0}
              survey={values.questions[0]}
              namePrefix={''}
              addNewOption={() => {
                const newOptions = [...values.questions[0].options];
                newOptions[newOptions.length] = { title: '' };
                const newQuestions = [...values.questions];
                newQuestions[0].options = newOptions;
                formik.setValues({ ...values, questions: newQuestions });
              }}
            />
            {/* @ts-ignore */}
            <ErrorLabel>{errors.questions?.[0]?.options?.[0]?.correctAnswer}</ErrorLabel>
            <NewButton
              onClick={async () => {
                await formik.submitForm();
              }}
              className="mt-2"
            >
              {btn.saveBtn}
            </NewButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withStyles(styles)(withConfig(CommentToolbar));
