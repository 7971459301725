import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { SignUpManualProps, SignUpManualState } from './model';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUpManualRequest, signUpRequest } from '../../store/actions/auth';
import { getCorpsNamesRequest } from '../../store/actions/corps';
import { withConfig } from '../../config';
import Logo from '../../components/UI/Logo';
import Input from '../../components/UI/Input';
import TouchableInput from '../../components/UI/TouchableInput';
import Heading from '../../components/Shared/Heading';
import Button from '../../components/UI/Button';
import { validateInput, patterns, countries, debounce } from '../../utilities';
import SearchableModal from '../../components/Shared/SearchableModal';
import SearchableInput from '../../components/Shared/SearchableInput';
import Loader from '../../components/UI/Loader';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import { RootState } from 'store';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { Box } from '@material-ui/core';
import Conditions from 'scenes/SignIn/components/Conditions';

const { config, theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 0 30px 0',
  },
  wrapper: {
    width: 320,
  },
  inputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  postInputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  postInputWrapper: {
    width: '49%',
  },
  descriptionTitle: {
    fontSize: 14,
    color: theme.TEXT_SECONDARY,
    letterSpacing: 0.1,
    alignSelf: 'flex-start',
    marginLeft: 20,
    bottom: 5,
  },
  descriptionWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme.BACKGROUND_SECONDARY,
    border: 'solid',
    padding: 10,
    width: '94%',
    margin: '10px 0',
  },
  description: {
    fontSize: 12,
    color: theme.TEXT_PRIMARY,
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, SignUpManualProps {
  language: translationsType;
  common: RootState['common'];
}

class SignUpPage extends React.Component<Props, SignUpManualState> {
  state: any = {
    showCountriesModal: false,
    showCorpsModal: false,
    showGeschlechtModal: false,
    countriesList: null,
    corpsList: null,
    currentCountry: 'Deutschland',
    selectedCorps: '',
    currentCorps: '',
    firstName: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    lastName: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    email: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    emailRepeat: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    mobilfunknummer: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    password: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    postCode: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    city: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    country: {
      value: 'Deutschland',
      isValid: true,
      error: '',
      isFocused: false,
    },
    corps: {
      value: [] as string[],
      isValid: false,
      error: '',
      isFocused: false,
    },
    loginCode: {
      value: '',
      isValid: false,
      isFocused: false,
      error: '',
    },
    beruf: {
      value: '',
      isValid: false,
      isFocused: false,
      error: '',
    },
    street: {
      value: '',
      isValid: false,
      isFocused: false,
      error: '',
    },
    birthday: {
      value: '',
    },
  };

  onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    if (name === 'currentCorps') {
      this.getCorpsNamesDebounced(value);
    }
    this.setState({
      [name]: value,
    });
  };

  selectItemHandler = (value: string, inputName: string): void => {
    this.setState({
      [inputName]: value,
      selectedCorps: inputName === 'currentCorps' ? value : '',
    });
  };

  modalHandler = (modalName: string): void | boolean => {
    let modalState: string;
    let currentField: string;

    switch (modalName) {
      case 'corps':
        modalState = 'showCorpsModal';
        currentField = 'currentCorps';
        break;
      case 'country':
        modalState = 'showCountriesModal';
        currentField = 'currentCountry';
        break;
      default:
        return false;
    }

    this.setState((prevState) => ({
      [currentField]: '',
      selectedCorps: '',
      [modalState]: !prevState[modalState],
    }));
  };

  configData = (data: Array<any>, name: string): Array<any> => {
    let dataArray: Array<any> = [];
    if (name === 'corps') {
      dataArray = data
        .map((corps) => ({
          _id: corps._id,
          name: corps.corpsname,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      dataArray = data.map((country, i) => ({
        id: i,
        name: country,
      }));
    }

    return dataArray;
  };

  componentDidMount() {
    this.getCorpsHandler();
  }

  componentDidUpdate(prevProps) {
    this.configDataHandler(prevProps);
  }

  getCorpsHandler = (value?: string | undefined) => {
    const { getCorpsNamesRequest } = this.props;
    const { isGuest = false } = this.props.location.state;

    getCorpsNamesRequest(value || '', isGuest);
  };
  getCorpsNamesDebounced = debounce(this.getCorpsHandler, 700);

  configDataHandler = (prevProps) => {
    const { corpsNames } = this.props;
    const { countriesList, corpsList } = this.state;

    if (!countriesList && !corpsList && corpsNames) {
      this.setState({
        countriesList: this.configData(countries, 'countries'),
        corpsList: this.configData(corpsNames, 'corps'),
      });
    } else if (prevProps.corpsNamesIsLoading && !this.props.corpsNamesIsLoading) {
      this.setState({
        corpsList: this.configData(corpsNames, 'corps'),
      });
    }
  };

  configConfirmField = (value: string, name: string): object => {
    const { invalidInputs } = this.props.language;
    let error, isValid;
    if (value?.trim().length === 0) {
      error = invalidInputs.fieldRequired;
      isValid = false;
    } else if (value !== this.state[name].value) {
      error =
        name === 'email'
          ? 'E-Mail und E-Mail-Wiederholung stimmen nicht überein.'
          : 'Passwort und -wiederholung stimmen nicht überein.';
      isValid = false;
    } else if (value === this.state[name].value) {
      isValid = true;
    }

    return {
      error,
      isValid,
    };
  };

  validateValues = (value: string, name: string) => {
    const { invalidInputs } = this.props.language;
    let isValid = false;
    let patternType = '';
    let error = '';
    let repeatMailError = '';
    let newEmailRepeat = this.state.emailRepeat;

    switch (name) {
      case 'email':
        patternType = 'email';
        value = value?.trim();
        error = value?.length > 0 ? 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt!' : invalidInputs?.fieldRequired;
        repeatMailError = value !== this.state.emailRepeat.value ? invalidInputs.emailsNotMatching : '';

        newEmailRepeat = {
          ...this.state.emailRepeat,
          value: this.state.emailRepeat.value,

          error: repeatMailError,
          isValid: value === this.state.emailRepeat.value,
        };

        break;

      case 'emailRepeat':
        patternType = 'email';
        value = value?.trim();

        error = value !== this.state.email.value ? invalidInputs.emailsNotMatching : invalidInputs.fieldRequired;
        break;

      case 'password':
        value = value?.replace(/(\s)/g, '');
        patternType = 'password';
        error = !value.trim().length ? invalidInputs.fieldRequired : '';
        break;

      case 'corps':
        //@ts-ignore
        error = !!value?.length ? '' : invalidInputs.corpsInvalid;
        //@ts-ignore
        isValid = !!value?.length;
        break;

      default:
        patternType = 'required';
        error = invalidInputs.fieldRequired;
    }

    if (name !== 'emailRepeat' && name !== 'corps') {
      isValid = validateInput(value, patterns[patternType]);
    }

    if (name === 'emailRepeat') {
      isValid = value === this.state.email.value;
    }

    if (name === 'password' && !isValid) error = invalidInputs.passwordChars;
    // 'Password must contain minimum eight characters, at least one letter, one number and one special character';

    return { newEmailRepeat, isValid, error: isValid ? '' : error };
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    let { value } = e.target;
    const { newEmailRepeat, isValid, error } = this.validateValues(value, name);
    this.setState({
      searchIsFocused: true,
      emailRepeat: newEmailRepeat,
      [name]: {
        ...this.state[name],
        value,
        isValid,
        error: isValid ? '' : error,
        isFocused: true,
      },
    });
  };

  setSearchedValueHandler = (modalName: string): void | boolean => {
    let modalState: string;
    let currentField: string;

    switch (modalName) {
      case 'corps':
        modalState = 'showCorpsModal';
        currentField = 'currentCorps';
        break;
      case 'country':
        modalState = 'showCountriesModal';
        currentField = 'currentCountry';
        break;
      default:
        return false;
    }

    this.setState({
      [modalName]: {
        ...this.state[modalName],
        value: this.state[currentField],
        error: '',
        isValid: true,
      },
      [modalState]: false,
    });
  };

  focusHandler = (name: any): void => {
    const { error, isValid } = this.validateValues(this.state[name].value, name);
    this.setState({
      [name]: {
        ...this.state[name],
        isFocused: !this.state[name].isFocused,
        error,
        isValid,
      },
    });

    if (name === 'email' || (name === 'emailRepeat' && this.state[name].isFocused)) {
      this.setState({
        [name]: {
          ...this.state[name],
          isFocused: !this.state[name].isFocused,
          error,
          isValid,
        },
      });
    }
  };

  signUpHandler = () => {
    const {
      firstName,
      lastName,
      email,
      emailRepeat,
      password,
      corps,
      postCode,
      city,
      country,
      mobilfunknummer,
      loginCode,
      beruf,
      street,
    } = this.state;
    const { isInvitationCodeAvailable, isGuest = false } = this.props.location.state;

    const isLoginCodeValid = isInvitationCodeAvailable && !isGuest ? loginCode.value : !!corps.value?.length;
    const isCorpsValid = isInvitationCodeAvailable && !isGuest ? true : !!corps.value?.length;
    const additionalFieldsValidity = config.APP_NAME === 'soroptimist' ? beruf.isValid && street.isValid : true;

    const registerDisabled =
      isLoginCodeValid &&
      firstName.isValid &&
      lastName.isValid &&
      email.isValid &&
      emailRepeat.isValid &&
      password.isValid &&
      city.isValid &&
      postCode.isValid &&
      country.value &&
      isCorpsValid &&
      mobilfunknummer.isValid &&
      additionalFieldsValidity;

    if (!registerDisabled) {
      const fieldsToValidate = [
        'firstName',
        'lastName',
        'email',
        'emailRepeat',
        'password',
        'corps',
        'postCode',
        'city',
        'street',
        'mobilfunknummer',
        'loginCode',
      ];
      fieldsToValidate.forEach((el) => {
        const { error, isValid } = this.validateValues(this.state[el].value, el);
        this.setState({
          [el]: { ...this.state[el], error, isValid },
        });
      });
      return;
    }
    const { signUpManualRequest, signUpRequest } = this.props;

    const userEntity = {
      name: firstName.value,
      lastname: lastName.value,
      email: email.value,
      password: password.value,
      mobilfunknummer: mobilfunknummer.value,
      postcode: postCode.value,
      wohnort: city.value,
      country: country.value,
      beruf: beruf.value,
      street: street.value,
      isGuest,
    };

    if (corps.value.length) {
      const corpsArr = corps.value?.map((el) => ({
        charge: [],
        corpsname: el.name,
        rezeptionsdatum: '',
        status: '',
      }));
      return signUpManualRequest(
        {
          ...userEntity,
          corp: corpsArr,
        },
        false,
      );
    }

    if (loginCode.value) {
      return signUpRequest({ ...userEntity, invitationCode: loginCode.value }, true);
    }
  };

  navigationHandler = (route: string): void => {
    const { history } = this.props;

    history.push(route);
  };

  addCorpHandler = (value: { name: string; _id: string }) => {
    const { isValid, error } = this.validateValues(value.name, 'corps');

    this.setState({
      corps: {
        ...this.state.corps,
        value: [...this.state.corps.value, value],
        isValid,
        error,
      },
    });
  };

  deleteCorpsHandler = (value: { name: string; _id: string }) => {
    const newItem = this.state.corps.value.filter((item) => item._id !== value._id);
    const { isValid, error } = this.validateValues(newItem, 'corps');
    this.setState({
      ...this.state,
      corps: { ...this.state.corps, value: newItem, isValid, error },
    });
  };

  render(): JSX.Element {
    const { classes, authIsLoading, language } = this.props;
    const {
      maximal_membership_count,
      sign_up_description,
      sign_up_description_with_code,
      sign_up_description_guests,
      sign_up_description_without_code,
    } = this.props.common;
    const {
      firstName,
      lastName,
      email,
      emailRepeat,
      password,
      postCode,
      city,
      country,
      corps,
      countriesList,
      currentCountry,
      currentCorps,
      mobilfunknummer,
      loginCode,
      beruf,
      street,
    } = this.state;

    const { isInvitationCodeAvailable, isGuest } = this.props.location.state;
    const isLoginCodeValid = isInvitationCodeAvailable && !isGuest ? loginCode.value : true;
    const isCorpsValid = isInvitationCodeAvailable && !isGuest ? true : !!corps.value?.length;
    const additionalFieldsValidity = config.APP_NAME === 'soroptimist' ? beruf.isValid && street.isValid : true;

    const registerEnabled =
      isLoginCodeValid &&
      firstName.isValid &&
      lastName.isValid &&
      email.isValid &&
      emailRepeat.isValid &&
      password.isValid &&
      city.isValid &&
      postCode.isValid &&
      country.value &&
      isCorpsValid &&
      mobilfunknummer.isValid &&
      additionalFieldsValidity;

    const hint = isGuest
      ? sign_up_description_guests || sign_up_description
      : isInvitationCodeAvailable
      ? sign_up_description_with_code || sign_up_description
      : sign_up_description_without_code || sign_up_description;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Logo />
            <Heading title={language.signUpManual.title} />
            <div className={classes.inputsWrapper}>
              {!!hint?.length && (
                <>
                  <span className={classes.descriptionTitle}>{language.signUp.description}</span>
                  <div className={classes.descriptionWrapper}>
                    <span className={classes.description}>{hint}</span>
                  </div>
                </>
              )}
              {/* <DatePickerDemo /> */}
              <Input
                placeholder={language.signUpManual.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'firstName')}
                autoCapitalize="none"
                error={firstName.error}
                value={firstName.value}
                isFocused={firstName.isFocused}
                onBlur={() => this.focusHandler('firstName')}
                onFocus={() => this.focusHandler('firstName')}
              />
              <Input
                placeholder={language.signUpManual.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'lastName')}
                autoCapitalize="none"
                error={lastName.error}
                value={lastName.value}
                isFocused={lastName.isFocused}
                onBlur={() => this.focusHandler('lastName')}
                onFocus={() => this.focusHandler('lastName')}
              />
              {isInvitationCodeAvailable && !isGuest ? (
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'loginCode')}
                  autoCapitalize="none"
                  placeholder={language.signUpManual.loginCode}
                  value={loginCode.value}
                  onFocus={() => this.focusHandler('loginCode')}
                  onBlur={() => this.focusHandler('loginCode')}
                  isFocused={loginCode.isFocused}
                  error={loginCode.error}
                />
              ) : (
                <>
                  <TouchableInput
                    onClick={() => this.modalHandler('corps')}
                    value={undefined}
                    placeholder={language.signUpManual.corps}
                    error={this.state.corps.error}
                  />
                  <Box style={{ width: '90%', marginBottom: 10 }}>
                    {this.state.corps?.value?.map((item) => (
                      <MultipleModalItem
                        item={item}
                        deleteItemHandler={(value) => this.deleteCorpsHandler(value)}
                        name="selectedCorps"
                      />
                    ))}
                  </Box>
                </>
              )}
              <Input
                placeholder={language.signUpManual.mobilePlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'mobilfunknummer')}
                isFocused={mobilfunknummer.isFocused}
                onBlur={() => this.focusHandler('mobilfunknummer')}
                onFocus={() => this.focusHandler('mobilfunknummer')}
                autoCapitalize="none"
                error={mobilfunknummer.error}
                value={mobilfunknummer.value}
              />
              <Input
                placeholder={language.signUpManual.emailPlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'email')}
                autoCapitalize="none"
                error={email.error}
                value={email.value}
                isFocused={email.isFocused}
                onBlur={() => this.focusHandler('email')}
                onFocus={() => this.focusHandler('email')}
                for="email"
                name="email"
              />
              <Input
                placeholder={language.signUpManual.repeatEmailPlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'emailRepeat')}
                autoCapitalize="none"
                error={emailRepeat.error}
                value={emailRepeat.value}
                isFocused={emailRepeat.isFocused}
                onBlur={() => this.focusHandler('emailRepeat')}
                onFocus={() => this.focusHandler('emailRepeat')}
                for="email"
                name="email"
              />
              <Input
                security
                placeholder={language.signUpManual.passwordPlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'password')}
                isFocused={password.isFocused}
                error={password.error}
                onBlur={() => this.focusHandler('password')}
                onFocus={() => this.focusHandler('password')}
                value={password.value}
                for="password"
                name="password"
                autocomplete="new-password"
              />
              {config.APP_NAME === 'soroptimist' && (
                <>
                  <Input
                    placeholder={language.profileProfession.itemNameLabel}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'beruf')}
                    isFocused={beruf.isFocused}
                    error={beruf.error}
                    onBlur={() => this.focusHandler('beruf')}
                    onFocus={() => this.focusHandler('beruf')}
                    value={beruf.value}
                  />
                  <Input
                    placeholder={language.profileDetails.street}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'street')}
                    isFocused={street.isFocused}
                    onBlur={() => this.focusHandler('street')}
                    onFocus={() => this.focusHandler('street')}
                    autoCapitalize="none"
                    value={street.value}
                    error={street.error}
                  />
                </>
              )}
              <div className={classes.postInputsWrapper}>
                <div className={classes.postInputWrapper}>
                  <Input
                    placeholder={language.signUpManual.postcode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'postCode')}
                    isFocused={postCode.isFocused}
                    error={postCode.error}
                    onBlur={() => this.focusHandler('postCode')}
                    onFocus={() => this.focusHandler('postCode')}
                    autoCapitalize="none"
                    value={postCode.value}
                  />
                </div>
                <div className={classes.postInputWrapper}>
                  <Input
                    placeholder={language.signUpManual.place}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'city')}
                    isFocused={city.isFocused}
                    error={city.error}
                    onBlur={() => this.focusHandler('city')}
                    onFocus={() => this.focusHandler('city')}
                    autoCapitalize="none"
                    value={city.value}
                  />
                </div>
              </div>
              <TouchableInput
                onClick={() => this.modalHandler('country')}
                value={this.state.country.value}
                placeholder={language.signUpManual.country}
              />
            </div>
            {!registerEnabled ? (
              <Button label={language.signUpManual.title} onClick={this.signUpHandler} background={'#ccc'} />
            ) : (
              <Button label={language.signUpManual.title} onClick={this.signUpHandler} />
            )}
            <Conditions language={language} type="signUp" />
          </div>
        </div>
        <SearchableModal
          modalOpen={this.state.showCountriesModal}
          onClick={() => this.modalHandler('country')}
          modalTitle={language.searchModal.landTitle}
          setValueHandler={() => this.setSearchedValueHandler('country')}
        >
          <SearchableInput
            data={countriesList || []}
            onClick={() => this.modalHandler('country')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSearchHandler(e, 'currentCountry')}
            value={currentCountry}
            onItemSelect={this.selectItemHandler}
            inputName="currentCountry"
          />
        </SearchableModal>
        <SearchableModal
          modalOpen={this.state.showCorpsModal}
          modalTitle={`Bitte auswählen`}
          onClick={() => this.modalHandler('corps')}
          // setValueHandler={() => this.setSearchedValueHandler('corps')}
          setValueHandler={() => this.setState({ showCorpsModal: false })}
          value={this.state.corps.value}
        >
          <MultipleSearchableInput
            data={this.state.corpsList || []}
            onSelect={this.addCorpHandler}
            currentName="currentCorps"
            placeholder={language.drawer.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSearchHandler(e, 'currentCorps')}
            deleteItemHandler={this.deleteCorpsHandler}
            values={this.state.corps.value}
            value={currentCorps}
            // isFocused={this.state.searchIsFocused}
            inputName="corps"
            disabled={this.state.corps.value.length >= maximal_membership_count}
          />
        </SearchableModal>

        <Loader showLoader={authIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  corpsNames: state.corps.corpsNames,
  corpsNamesIsLoading: state.corps.corpsNamesIsLoading,
  authIsLoading: state.auth.authIsLoading,
  common: state.common,
});

const mapDispatchToProps = {
  getCorpsNamesRequest,
  signUpManualRequest,
  signUpRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withLanguage(withConfig(SignUpPage))));
