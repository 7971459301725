import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DELETE_OWNER_FAILURE, DELETE_OWNER_REQUEST, DELETE_OWNER_SUCCESS } from 'constants/auth';
import {
  SIGN_UP_MANUAL_REQUEST,
  SIGN_UP_MANUAL_SUCCESS,
  SIGN_UP_MANUAL_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_IN_GOOGLE_REQUEST,
  SIGN_IN_GOOGLE_SUCCESS,
  SIGN_IN_GOOGLE_FAILURE,
  GET_ACCOUNT_INFO_GOOGLE_REQUEST,
  GET_ACCOUNT_INFO_GOOGLE_SUCCESS,
  GET_ACCOUNT_INFO_GOOGLE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from '../../constants';
import { signIn } from 'api';
import { signUpWithCode, signUpWithoutCode } from 'api/auth';
import { firebaseSignIn } from '../../firebase';
import { setStorage } from 'utilities';
import { showResponse } from 'store/actions/response';
import { RootState } from 'store';

type StateTypes = {
  googleCreds: {}; // no google registration ON THE WEB
  accountInfo: {}; // no google registration and no account info for google accounts ON THE WEB
  authIsLoading: boolean;
  currentCorps: any;
  signedIn: boolean;
  signedUp: boolean;
  signedUpError: string;
};

export interface ISignUp {
  name: string;
  lastname: string;
  email: string;
  emailRepeat: string;
  mobilfunknummer: string;
  password: string;
  postcode: string;
  wohnort: string;
  country: string;
  corp?: any[];
  invitationCode?: string;
  beruf: string;
  street: string;
  birthday?: Date | string;
  isGuest?: boolean;
}

const initialState: StateTypes = {
  googleCreds: {},
  accountInfo: {},
  authIsLoading: false,
  currentCorps: null,
  signedIn: false,
  signedUp: false,
  signedUpError: '',
};

export const login = createAsyncThunk(
  'auth/signIn',
  async (data: { email: string; password: string; redirectTo: string }, { dispatch }) => {
    const { email, password, redirectTo } = data;
    try {
      const res = await signIn({ email, password });

      firebaseSignIn(email, password);

      setStorage({
        token: res.Token,
        userId: res.Data._id,
        firebaseID: res.Data.firebaseID,
        userLang: res.Data.language,
      });
      if (redirectTo) {
        window.open(redirectTo, '_blank');
      }
    } catch (err) {
      dispatch(showResponse({ message: err.message || err.Status || err }));
    }
  },
);

export const registration = createAsyncThunk(
  'auth/signUp',
  async (
    {
      messageTexts,
      data,
    }: {
      data: ISignUp;
      messageTexts: {
        thankYou: string;
        thankYouWithoutCode: string;
        guestActivatedTitle: string;
        guestActivatedMessage: string;
      };
    },
    { dispatch, getState },
  ) => {
    try {
      const { invitationCode, email, password } = data;
      const state = getState() as RootState;
      let res;
      const message = invitationCode
        ? messageTexts.thankYou
        : state.common.activate_guests_automatically
        ? messageTexts.guestActivatedMessage
        : messageTexts.thankYouWithoutCode;

      const title = state.common.activate_guests_automatically ? messageTexts.guestActivatedTitle : '';

      const route = invitationCode ? '/posts' : '/sign-in';

      if (invitationCode) {
        res = await signUpWithCode(data);
      } else {
        res = await signUpWithoutCode(data);
      }

      const firebase = firebaseSignIn(email, password);
      if (!res || !firebase) {
        return;
      }
      if (invitationCode) {
        setStorage({ token: res.Token, userId: res.Data });
      } else {
        setStorage({ userId: res.Data, email, password });
      }

      dispatch(showResponse({ message, title }, route));

      return res;
    } catch (err) {
      throw new Error(err.message || err.Status || err);
    }
  },
);

//

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /// sign in
    builder.addCase(login.pending, (state) => {
      state.authIsLoading = true;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.authIsLoading = false;
      state.signedIn = true;
      state.currentCorps = null;
    });
    builder.addCase(login.rejected, (state) => {
      state.authIsLoading = false;
      state.signedIn = false;
    });
    /// sign up
    builder.addCase(registration.pending, (state) => {
      state.authIsLoading = true;
    });
    builder.addCase(registration.fulfilled, (state, { payload }) => {
      state.authIsLoading = false;
      state.signedUp = true;
      state.currentCorps = payload?.currentCorps || null;
    });
    builder.addCase(registration.rejected, (state) => {
      state.authIsLoading = false;
      state.signedUp = false;
    });
  },
});

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        authIsLoading: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
        signedIn: false,
        signedUp: false,
      };
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        authIsLoading: false,
        signedIn: false,
        signedUp: false,
        error: action.error,
      };
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signedUp: false,
        authIsLoading: true,
        signedUpError: '',
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signedUp: true,
        authIsLoading: false,
        currentCorps: action.currentCorps,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signedUp: false,
        authIsLoading: false,
        signedUpError: action.error?.message,
      };
    case SIGN_UP_MANUAL_REQUEST:
      return {
        ...state,
        authIsLoading: true,
        signedUpError: '',
      };
    case SIGN_UP_MANUAL_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
        currentCorps: null,
      };
    case SIGN_UP_MANUAL_FAILURE:
      return {
        ...state,
        authIsLoading: false,
        signedUpError: action.error?.message,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        authIsLoading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        authIsLoading: false,
        error: action.error,
      };
    case SIGN_IN_GOOGLE_REQUEST:
      return {
        ...state,
      };
    case SIGN_IN_GOOGLE_SUCCESS:
      return {
        ...state,
        googleCreds: action.googleCreds,
      };
    case SIGN_IN_GOOGLE_FAILURE:
      return {
        ...state,
        error: action.error,
        authIsLoading: false,
      };
    case GET_ACCOUNT_INFO_GOOGLE_REQUEST:
      return {
        ...state,
      };
    case GET_ACCOUNT_INFO_GOOGLE_SUCCESS:
      return {
        ...state,
        accountInfo: action.accountInfo,
      };
    case GET_ACCOUNT_INFO_GOOGLE_FAILURE:
      return {
        ...state,
        error: action.error,
        authIsLoading: false,
      };
    // UPDATE PASSWORD
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        authIsLoading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        authIsLoading: false,
      };
    case DELETE_OWNER_REQUEST:
      return { ...state, authIsLoading: true };
    case DELETE_OWNER_SUCCESS:
      return { ...state, authIsLoading: false, signedIn: false, signedUp: false };
    case DELETE_OWNER_FAILURE:
      return {
        ...state,
        authIsLoading: false,
        signedIn: false,
        signedUp: false,
        error: action.error,
        signedUpError: '',
      };
    default:
      return {
        ...state,
      };
  }
};
